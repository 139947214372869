import { differenceInDays } from 'date-fns';

interface ExpiryFields {
  daysToExpiry?: number;
  expireYear?: number | null;
  expireMonth?: number | null;
  expireDay?: number | null;
  date?: string;
}

const getDaysToExpiry = (trade: ExpiryFields) => {
  if (trade.daysToExpiry) {
    return trade.daysToExpiry;
  }

  const tradeDate = trade.date ? new Date(trade.date) : new Date();
  const date = new Date();
  const year = trade.expireYear || date.getFullYear();
  const month = (trade.expireMonth || 1) - 1;
  const day = trade.expireDay || date.getDate();

  const expiryDate = new Date(year, month, day);

  return differenceInDays(expiryDate, tradeDate);
};

export default getDaysToExpiry;

import * as React from 'react';
import { defineCustomElements as ionDefineCustomElements } from '@ionic/core/loader';
import ThemeProvider from 'context/ThemeProvider';
import Head from 'next/head';
import { ApolloProvider } from '@apollo/client';
import { useApollo } from 'utils/client/apolloClient';
import { ViewerProvider } from 'context/ViewerContext';
import { CurrentUserProvider } from 'context/CurrentUserContext';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/next';
import UsagePing from 'components/UsagePing';
import { getIsNativePlatform } from 'utils/client/mobile';
import posthog, { PostHogProvider } from 'services/client/analytics/posthog';
import 'tailwindcss/tailwind.css';
import '@ionic/react/css/core.css';
import 'styles/globals.css';

import 'utils/shared/sentry';

import AffiliateTracker from 'components/AffiliateTracker';
import Script from 'next/script';

const APP_VIEWPORT = 'viewport-fit=cover, width=device-width, initial-scale=1, maximum-scale=1';
const WEB_VIEWPORT = 'width=device-width, initial-scale=1';

export default function MyApp({ Component, pageProps, err }: any) {
  // console.log(process.env.APP_STAGE);
  // console.log(process.env.NODE_ENV);
  // console.log(process.env.ROOT_URL);
  // console.log(process.env.APP_URL);
  // console.log(process.env.GRAPHQL_URL);
  const apolloClient = useApollo(pageProps);
  React.useEffect(() => {
    ionDefineCustomElements(window);
  }, []);
  const viewport = getIsNativePlatform() ? APP_VIEWPORT : WEB_VIEWPORT;

  return (
    <>
      <Head>
        <meta name="viewport" content={viewport} />
        <meta name="monetization" content="$ilp.uphold.com/4FZDaEHX9323" />
      </Head>
      <Script id="clarity-script" strategy="afterInteractive">
        {`(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "prrxvy3rqt");`}
      </Script>
      <ViewerProvider>
        <ApolloProvider client={apolloClient}>
          <CurrentUserProvider>
            <UsagePing>
              <ThemeProvider>
                <AffiliateTracker>
                  <PostHogProvider client={posthog}>
                    <Component {...pageProps} err={err} />
                  </PostHogProvider>
                </AffiliateTracker>
              </ThemeProvider>
            </UsagePing>
          </CurrentUserProvider>
        </ApolloProvider>
      </ViewerProvider>
      <Analytics />
      <SpeedInsights />
    </>
  );
}

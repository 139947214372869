import { ReactNode } from 'react';

import NavBar from 'components/Website/Navbar';
import TickerTapeBar from 'components/Website/TickerTapeBar';
import BottomBanner from 'components/Website/BottomBanner';
import Footer from 'layouts/Footer';
import {
  PRICING_PAGE,
  OPC_PAGE,
  // AFFILIATE_PROGRAM_ARTICLE_PAGE,
  MASTER_CLASS_ARTICLE_PAGE,
  BLOG_INDEX_PAGE,
  TECHNICAL_ANALYSIS_LANDING_PAGE,
  OPTION_FLOW_PAGE,
  CONGRESS_TRADES_PAGE,
  INSIDER_TRADES_PAGE,
} from 'constants/pages';
import classNames from 'styles/utils/classNames';
import { IS_FREE_FLOW_ENABLED } from 'constants/flags';

import { PageLayoutStyled } from './styles';

type LayoutProps = {
  children: ReactNode;
  fullwidth?: boolean;
  hideTopBar?: boolean;
  topBanner?: ReactNode;
  hasPadTop?: boolean;
  isNavBordered?: boolean;
};

const NAVIGATION_ITEMS = [
  {
    name: 'Product',
    href: '#',
    current: true,
    isMenu: true,
    subMenu: [
      {
        name: 'Options Flow',
        href: OPTION_FLOW_PAGE,
        current: true,
      },
      { name: 'Technical Analysis System', href: TECHNICAL_ANALYSIS_LANDING_PAGE, current: true },
      { name: 'Options Profit Calculator', href: OPC_PAGE, current: true },
      { name: 'Congress Trades', href: CONGRESS_TRADES_PAGE, current: true },
      { name: 'Company Insider Trades', href: INSIDER_TRADES_PAGE, current: true },
    ],
  },
  { name: 'Pricing', href: PRICING_PAGE, current: false },
  { name: 'Options Profit Calculator', href: OPC_PAGE, current: false },
  // { name: 'Affiliate Program', href: AFFILIATE_PROGRAM_ARTICLE_PAGE, current: false },
  { name: 'Free Masterclass', href: MASTER_CLASS_ARTICLE_PAGE, current: false },
  IS_FREE_FLOW_ENABLED
    ? { name: 'Options Flow', href: OPTION_FLOW_PAGE, current: false }
    : { name: 'Resources', href: BLOG_INDEX_PAGE, current: false },
];

export default function PageLayout({
  children,
  hideTopBar,
  topBanner,
  fullwidth = false,
  hasPadTop,
  isNavBordered,
}: LayoutProps) {
  return (
    <PageLayoutStyled>
      <div className="fixed left-0 top-0 z-30 w-full">
        {!!topBanner && topBanner}
        {!hideTopBar && <TickerTapeBar />}
        <NavBar navitems={NAVIGATION_ITEMS} fullwidth={fullwidth} isNavBordered={isNavBordered} />
      </div>
      <div className="h-[4.5rem] md:h-14">&nbsp;</div>
      <div className={classNames('flex-1', hasPadTop && 'pt-20')}>{children}</div>
      <Footer fullwidth={fullwidth} />
      <BottomBanner />
    </PageLayoutStyled>
  );
}

import * as React from 'react';
import Link from 'components/Link';
import { FiMail } from 'react-icons/fi';
import { FaFacebookSquare, FaLinkedin, FaTwitter, FaArrowRight } from 'react-icons/fa';
import {
  HOME_PAGE,
  PRICING_PAGE,
  TERMS_OF_SERVICE_PAGE,
  MASTER_CLASS_ARTICLE_PAGE,
  OPC_PAGE,
  BLOG_INDEX_PAGE,
  CONGRESS_TRADES_PAGE,
  TECHNICAL_ANALYSIS_LANDING_PAGE,
  OPTION_FLOW_PAGE,
  INSIDER_TRADES_PAGE,
} from 'constants/pages';
import LogoImage from 'svg/logo';
import {
  COMPANY_TWITTER,
  COMPANY_FACEBOOK_PAGE,
  COMPANY_LINKEDIN,
  COMPANY_EMAIL_LINK,
} from 'constants/internal';

import classNames from 'styles/utils/classNames';

import {
  Wrapper,
  Content,
  ContentGroup,
  FooterSection,
  FooterLink,
  Disclaimer,
  FooterLogo,
  Copyright,
  BuyNowButton,
} from './styles';

interface Props {
  sidebar?: boolean;
  right?: boolean;
  fullwidth?: boolean;
}

const Footer: React.FC<React.PropsWithChildren<Props>> = ({
  sidebar,
  right,
  fullwidth = false,
}) => {
  return (
    <Wrapper sidebar={sidebar} right={right} className="styled-font">
      <Content className={classNames(fullwidth ? '!max-w-[100%]' : '')}>
        <ContentGroup>
          <FooterLogo>
            <Link href={HOME_PAGE}>
              <LogoImage />
            </Link>
          </FooterLogo>
          <p className="mt-4 text-left leading-normal" style={{ fontSize: '15px' }}>
            We are a financial intelligence company that provides traders the data, tools,
            education, and community to earn consistent income in the financial markets.
          </p>
          <div className="mt-5 flex flex-col gap-6 md:flex-row">
            <Link href={PRICING_PAGE}>
              <BuyNowButton className="styled-font">Buy Now</BuyNowButton>
            </Link>
            <div className="space-y-1 text-left">
              <div>
                <Link
                  href={MASTER_CLASS_ARTICLE_PAGE}
                  className="styled-font flex items-center underline"
                >
                  Free InsiderFinance Masterclass <FaArrowRight className="ml-2 w-3" />
                </Link>
              </div>
              <div>
                <Link href={OPC_PAGE} className="styled-font flex items-center underline">
                  Options Profit Calculator <FaArrowRight className="ml-2 w-3" />
                </Link>
              </div>
              <div>
                <Link href={BLOG_INDEX_PAGE} className="styled-font flex items-center underline">
                  Resource Center <FaArrowRight className="ml-2 w-3" />
                </Link>
              </div>
            </div>
          </div>
        </ContentGroup>
        <ContentGroup>
          <FooterSection>
            <div className="flex flex-col">
              <FooterLink href={COMPANY_EMAIL_LINK} isExternal>
                <FiMail /> team@insiderfinance.io
              </FooterLink>
              <FooterLink href={COMPANY_FACEBOOK_PAGE} isExternal>
                <FaFacebookSquare /> insiderfinance.io
              </FooterLink>
              <FooterLink href={COMPANY_TWITTER} isExternal>
                <FaTwitter /> @InsiderFinancex
              </FooterLink>
              <FooterLink href={COMPANY_LINKEDIN} isExternal>
                <FaLinkedin /> InsiderFinance
              </FooterLink>
              <FooterLink href={TERMS_OF_SERVICE_PAGE}>Terms and Conditions</FooterLink>
            </div>
          </FooterSection>
        </ContentGroup>
      </Content>
      <Content className={classNames(fullwidth ? '!max-w-[100%]' : '')}>
        <div className="w-full border-t border-palette-border pt-4 text-left">
          <h2 className="text-lg font-bold text-white">Products</h2>
          <div className="mt-4 flex flex-wrap items-center gap-4">
            <Link className="underline" href={OPTION_FLOW_PAGE}>
              Options Flow
            </Link>
            <Link className="underline" href={TECHNICAL_ANALYSIS_LANDING_PAGE}>
              Technical Analysis System
            </Link>
            <Link className="underline" href={OPC_PAGE}>
              Options Profit Calculator
            </Link>
            <Link className="underline" href={CONGRESS_TRADES_PAGE}>
              Congress Trades
            </Link>
            <Link className="underline" href={INSIDER_TRADES_PAGE}>
              Company Insider Trades
            </Link>
            <Link className="underline" href={MASTER_CLASS_ARTICLE_PAGE}>
              Trading Courses
            </Link>
          </div>
        </div>
      </Content>
      <Content className={classNames('flex-col', fullwidth ? '!max-w-[100%]' : '')}>
        <Copyright className="mb-2 mt-2 text-left font-semibold">
          InsiderFinance Intelligence LLC © {new Date().getFullYear()}
        </Copyright>
        <Disclaimer>
          <strong>Legal Disclaimer:</strong> Insider Finance Intelligence LLC (InsiderFinance) is
          not a registered investment advisor nor is licensed as such with any federal or state
          regulatory agency. InsiderFinance does not manage client assets in any way. Information
          provided and opinions expressed on this website do not constitute investment advise. The
          ideas expressed on this site and related services are solely the opinions of
          InsiderFinance and are for educational purposes only. We advise everyone to know the risks
          involved with trading stocks and options. We encourage every visitor to the website to do
          his/her own research and to consult with his/her own financial advisor(s) prior to
          engaging in any investing activities, and to make sure he/she knows all the potential
          risks involved. Any investment decision that results in losses or gains made based on any
          information on this site or related services is not the responsibility of InsiderFinance.
          InsiderFinance is solely a data-provider and not a stock picks alert service.
        </Disclaimer>
      </Content>
    </Wrapper>
  );
};

export default Footer;

import { RiPulseLine } from 'react-icons/ri';
import { VscBeaker } from 'react-icons/vsc';
import { BiArrowToBottom, BiCalendarWeek, BiCalculator } from 'react-icons/bi';
import { MdAccessTime } from 'react-icons/md';
import { AiOutlineFire } from 'react-icons/ai';
import { BsNewspaper } from 'react-icons/bs';
import { BiBitcoin, BiDollar } from 'react-icons/bi';

import { FaUser, FaUserSecret, FaBalanceScale } from 'react-icons/fa';

import {
  ProductionAlertPrices,
  DevAlertPrices,
  ProductionExclusiveEventPrices,
  DevExclusiveEventPrices,
  CHECKOUT_COMPLETE_PAGE,
} from 'constants/payments';
// import { FiLogOut } from 'react-icons/fi';

export { CHECKOUT_COMPLETE_PAGE };

const isProduction = process.env.APP_STAGE === 'production';

export const HOME_PAGE = '/';
export const OPTION_FLOW_PAGE = '/flow';
export const HISTORICAL_FLOW_PAGE = '/historical-flow';
export const TICKER_FLOW_PAGE = '/ticker-flow';
export const TOP_TICKERS_PAGE = '/top-tickers';
export const WEEKLY_OVERVIEW_PAGE = '/weekly-overview';
export const ALERTS_PAGE = '/alerts';
export const CRYPTO_PAGE = '/crypto';
export const FOREX_PAGE = '/forex';
export const NEWS_PAGE = '/chatter';
export const SOLD_PAGE = '/sold';
export const SIGNUP_PAGE = '/signup';
export const LOGIN_PAGE = '/login';
export const FORGOT_PASSWORD_PAGE = '/forgot-password';
export const LOGOUT_PAGE = '/logout';
export const ELECTRONIC_AGREEMENT_PAGE = '/my/electronic-agreement';
export const SETTINGS_PAGE = '/my/settings';
export const ALERTS_SETTINGS_PAGE = '/my/emails';
export const AFFILIATE_DASHBOARD_PAGE = '/my/affiliate';
export const TRADINGVIEW_USERNAME_PAGE = '/my/tradingview';
export const PRICING_PAGE = '/pricing';
export const EMAIL_VERIFICATION_PAGE = '/verify-email';
export const TERMS_OF_SERVICE_PAGE = '/terms-of-service';
export const DISCORD_AUTH_API = '/api/auth/discord';

export const OPC_PAGE = '/options-profit-calculator';
export const OPC_STRATEGY_PAGE = `${OPC_PAGE}/strategy`;

export const getOpcStrategyPage = (strategyType: string, ticker?: string) => {
  return `${OPC_STRATEGY_PAGE}/${strategyType}${ticker ? `/${ticker}` : ''}`;
};

export const BLOG_INDEX_PAGE = '/resources';
export const getBlogPostPage = (slug: string) => `${BLOG_INDEX_PAGE}/${slug}`;

export const COURSES_INDEX_PAGE = '/learn';
export const getMasterClassPage = (slug: string) => `${COURSES_INDEX_PAGE}/course/${slug}`;
export const getMasterClassLessonPage = (courseSlug: string, lessonSlug: string) =>
  `${COURSES_INDEX_PAGE}/course/${courseSlug}/lesson/${lessonSlug}`;

export const AFFILIATE_PROGRAM_ARTICLE_PAGE =
  '/resources/earn-monthly-income-with-the-insiderfinance-affiliate-program';
export const MASTER_CLASS_ARTICLE_PAGE = COURSES_INDEX_PAGE;

export const DASHBOARD_SECTION = 'dashboardOverview';
export const PRICING_SECTION = 'pricingCards';
export const TECHNICAL_ANALYSIS_LANDING_PAGE = '/technical-analysis';
export const EXCLUSIVE_EVENT_LANDING_PAGE = '/exclusive-event';

export const CONGRESS_TRADES_PAGE = '/congress-trades';
export const INSIDER_TRADES_PAGE = '/insider-trades';

export const getInsiderTradesPage = (ticker?: string) => {
  return `${OPC_STRATEGY_PAGE}/${ticker ? `/${ticker}` : ''}`;
};

export const MONTHLY_TIER = 'monthly';
export const QUARTERLY_TIER = 'quarterly';
export const YEARLY_TIER = 'annual';
export const DASHBOARD_REGISTER = 'dashboard';
export const DASHBOARD_SIGNUP_MONTHLY = `${SIGNUP_PAGE}/${DASHBOARD_REGISTER}/${MONTHLY_TIER}`;
export const DASHBOARD_SIGNUP_QUARTERLY = `${SIGNUP_PAGE}/${DASHBOARD_REGISTER}/${QUARTERLY_TIER}`;
export const DASHBOARD_SIGNUP_YEARLY = `${SIGNUP_PAGE}/${DASHBOARD_REGISTER}/${YEARLY_TIER}`;
export const TECHNICAL_ANALYSIS_SIGNUP_MONTHLY = `${SIGNUP_PAGE}/${
  isProduction ? ProductionAlertPrices.monthly : DevAlertPrices.monthly
}`;
export const TECHNICAL_ANALYSIS_SIGNUP_QUARTERLY = `${SIGNUP_PAGE}/${
  isProduction ? ProductionAlertPrices.quarterly : DevAlertPrices.quarterly
}`;
export const TECHNICAL_ANALYSIS_SIGNUP_YEARLY = `${SIGNUP_PAGE}/${
  isProduction ? ProductionAlertPrices.annual : DevAlertPrices.annual
}`;
export const DASHBOARD_LOGIN_MONTHLY = `${LOGIN_PAGE}/${DASHBOARD_REGISTER}/${MONTHLY_TIER}`;
export const DASHBOARD_LOGIN_QUARTERLY = `${LOGIN_PAGE}/${DASHBOARD_REGISTER}/${QUARTERLY_TIER}`;
export const DASHBOARD_LOGIN_YEARLY = `${LOGIN_PAGE}/${DASHBOARD_REGISTER}/${YEARLY_TIER}`;
export const TECHNICAL_ANALYSIS_LOGIN_MONTHLY = `${LOGIN_PAGE}/${
  isProduction ? ProductionAlertPrices.monthly : DevAlertPrices.monthly
}`;
export const TECHNICAL_ANALYSIS_LOGIN_QUARTERLY = `${LOGIN_PAGE}/${
  isProduction ? ProductionAlertPrices.quarterly : DevAlertPrices.quarterly
}`;
export const TECHNICAL_ANALYSIS_LOGIN_YEARLY = `${LOGIN_PAGE}/${
  isProduction ? ProductionAlertPrices.annual : DevAlertPrices.annual
}`;

// -- Exclusive event
export const EXCLUSIVE_EVENT_SIGNUP_MONTHLY = `${SIGNUP_PAGE}/${
  isProduction ? ProductionExclusiveEventPrices.monthly : DevExclusiveEventPrices.monthly
}`;
export const EXCLUSIVE_EVENT_SIGNUP_QUARTERLY = `${SIGNUP_PAGE}/${
  isProduction ? ProductionExclusiveEventPrices.quarterly : DevExclusiveEventPrices.quarterly
}`;
export const EXCLUSIVE_EVENT_SIGNUP_YEARLY = `${SIGNUP_PAGE}/${
  isProduction ? ProductionExclusiveEventPrices.annual : DevExclusiveEventPrices.annual
}`;
export const EXCLUSIVE_EVENT_LOGIN_MONTHLY = `${LOGIN_PAGE}/${
  isProduction ? ProductionExclusiveEventPrices.monthly : DevExclusiveEventPrices.monthly
}`;
export const EXCLUSIVE_EVENT_LOGIN_QUARTERLY = `${LOGIN_PAGE}/${
  isProduction ? ProductionExclusiveEventPrices.quarterly : DevExclusiveEventPrices.quarterly
}`;
export const EXCLUSIVE_EVENT_LOGIN_YEARLY = `${LOGIN_PAGE}/${
  isProduction ? ProductionExclusiveEventPrices.annual : DevExclusiveEventPrices.annual
}`;

export const authPages = [FORGOT_PASSWORD_PAGE];

export const privatePages = [
  // OPTION_FLOW_PAGE,
  // HISTORICAL_FLOW_PAGE,
  // TICKER_FLOW_PAGE,
  // TOP_TICKERS_PAGE,
  // WEEKLY_OVERVIEW_PAGE,
  // CRYPTO_PAGE,
  // FOREX_PAGE,
  // NEWS_PAGE,
  // ELECTRONIC_AGREEMENT_PAGE,
  SETTINGS_PAGE,
  // ALERTS_SETTINGS_PAGE,
];

export const PageIcons = {
  OptionFlow: RiPulseLine,
  Opc: BiCalculator,
  TickerResearch: VscBeaker,
  TopTickers: AiOutlineFire,
  WeeklyOverview: BiCalendarWeek,
  HistoricalFlow: MdAccessTime,
  Crypto: BiBitcoin,
  Forex: BiDollar,
  News: BsNewspaper,
  Sold: BiArrowToBottom,
  CongressTrades: FaBalanceScale,
  InsiderTrades: FaUserSecret,
};

export const SIDEBAR_TOP_ITEMS = [
  {
    href: OPTION_FLOW_PAGE,
    Icon: PageIcons.OptionFlow,
    name: 'Flow',
  },
  {
    href: getOpcStrategyPage('long-call'),
    Icon: PageIcons.Opc,
    name: 'Options Profit Calculator',
  },
  {
    href: TICKER_FLOW_PAGE,
    Icon: PageIcons.TickerResearch,
    name: 'Ticker Research',
  },
  {
    href: CRYPTO_PAGE,
    Icon: PageIcons.Crypto,
    name: 'Cryptocurrency',
  },
  {
    href: FOREX_PAGE,
    Icon: PageIcons.Forex,
    name: 'Forex',
  },
  {
    href: TOP_TICKERS_PAGE,
    Icon: PageIcons.TopTickers,
    name: 'Top Tickers',
  },
  // {
  //   href: CONGRESS_TRADES_PAGE,
  //   Icon: PageIcons.CongressTrades,
  //   name: 'Congress Trades',
  // },
  {
    href: NEWS_PAGE,
    Icon: PageIcons.News,
    name: 'News with Sentiment',
  },
  // {
  //   href: INSIDER_TRADES_PAGE,
  //   Icon: PageIcons.InsiderTrades,
  //   name: 'Insider Trades',
  // },
];

export const SIDEBAR_BOTTOM_ITEMS = [
  // {
  //   href: LOGOUT_PAGE,
  //   Icon: FiLogOut,
  //   name: 'Logout',
  // },
  {
    href: SETTINGS_PAGE,
    Icon: FaUser,
    name: 'Settings',
  },
];

// API ROUTES
export const UPGRADE_SUBSCRIPTION_API = 'payments/stripe/subscriptions/upgrade-newest';
